
import { ChangeEvent, FC, KeyboardEvent } from "react";
import { Button } from "@storybook";

import "./input.scss";

interface IInput {
  label?: string;
  inputType: "text" | "number" | "password" | "date";
  placeholder: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  isCountryCodeVisible?: boolean;
  // handleChangeCountry?: (e: ICountries) => void;
  isShowButton?: boolean;
  handleClickBtn?: () => void;
  errorMessage?: string;
  isError?: boolean;
  handleClearField?: () => void;
  handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  allowClear?: boolean;
  id?: string;
  handleBlur?: () => void;
  prefixIcon?: string;
  disabled?: boolean;
  suffixIcon?: string;
  handleSuffixIcon?: () => void;
  autoComplete?: string;
  countryCode?: string;
  inputName?: string;
  maxLength?: number;
  autoFocus?: boolean;
}

export const Input: FC<IInput> = ({
  label,
  inputType,
  id,
  placeholder,
  handleChange,
  value,
  handleKeyPress,
  allowClear = false,
  handleClearField,
  isError = false,
  errorMessage,
  isShowButton,
  handleClickBtn,
  handleBlur = () => {},
  prefixIcon,
  disabled = false,
  suffixIcon,
  handleSuffixIcon,
  // countryCode,
  autoComplete,
  inputName,
  maxLength,
  autoFocus = false,
}) => {
  return (
    <div className="input">
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
        </label>
      )}
      <div className="input__value-container">
        <div className="input__group">
          <div
            className={`input__text-field input__text-field${
              isError ? "--error" : ""
            }`}
          >
            {/* {isCountryCodeVisible && (
              <CountryCode
                handleChangeCountry={handleChangeCountry as any}
                countryCode={countryCode}
              />
            )} */}
            {prefixIcon && <i className={prefixIcon} />}
            <input
              placeholder={placeholder}
              type={inputType}
              name={inputName}
              onBlur={handleBlur}
              id={id}
              className="input__field"
              onChange={handleChange}
              value={value}
              onKeyPress={handleKeyPress}
              disabled={disabled}
              autoComplete={autoComplete}
              maxLength={maxLength}
              autoFocus = {autoFocus}
            />
            {allowClear && (
              <i
                className="ri-close-line input__delete"
                onClick={handleClearField}
              />
            )}
            {suffixIcon && (
              <i
                className={`${suffixIcon} input__view`}
                onClick={handleSuffixIcon}
              />
            )}
          </div>
          {isShowButton && (
            <Button
              label="Add"
              handleClick={handleClickBtn}
              type="button__outline button__large"
            />
          )}
        </div>

        {isError && errorMessage && (
          <span className="input__error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
