export const USER_ROLE_TYPE = [
    {
      lebel: "Administration",
      value: "administration",
    },
    {
      lebel: "Board Members",
      value: "board-members",
    },
    {
      lebel: "Law firm",
      value: "law-firm",
    },
    {
      lebel: "Signatories",
      value: "signatories",
    },
  ];

  export const SUB_ROLE_TYPE = [
    {
      label: "Director",
      value: "director",
    },
    {
      label: "Observer",
      value: "observer",
    },
   
  ];

export const ROLE_PERMISSION_COLOUMN = [
  {
    label: "Name",
    key: "name",
    format: "string",
    width: "15%",
  },

  {
    label: "Email",
    key: "email",
    key2: "contact",
    format: "jsx",
    width: "20%",
    className: "email__contact",
  },
  {
    label: "Role",
    key: "role",
    format: "string",
    width: "15%",
  },
  {
    label: "Start Date",
    key: "date",
    key2: "date",
    format: "jsx",
    width: "15%",
  },
  {
    label: "End Date",
    key: "date",
    key2: "date",
    format: "jsx",
    width: "15%",
  },
  {
    label: "Status",
    key: "status",
    format: "jsx",
    width: "15%",
  },
];  