import { ReactResponsiveTable } from "@storybook/react-responsive-table";
import { useCallback, useMemo, useState } from "react";
import { AddMember } from "../add-member-Modal";
import { USER_ROLE_TYPE, ROLE_PERMISSION_COLOUMN } from "../constant";
import "./role-permission-body.scss";

export const RolePermissionBody = () => {
  const [selectedUserTypeRole, setSelectedUserTypeRole] =
    useState<string>("administration");
  const [showAddMemberModal, setShowAddMemberModal] = useState<boolean>(false);

  const mapUserTypesRole = useMemo(() => {
    return USER_ROLE_TYPE.map((type) => (
      <div
        onClick={() => setSelectedUserTypeRole(type.value)}
        className={`role-box__user-role-left-user-types ${
          selectedUserTypeRole === type.value ? "--user-role-active" : ""
        }`}
      >
        {type.lebel}
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTypeRole]);

  const handleAddnewModal = useCallback(() => {
    setShowAddMemberModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowAddMemberModal(false);
  }, []);
  console.log("jnagra", showAddMemberModal);
  return (
    <div className="role-permission-wrapper">
      <div className="role-box">
        <div className="role-box__user-role-left">{mapUserTypesRole}</div>
        <div className="role-box__user-role-right">
          <div className="role-box__right-body-wrapper">
            <ReactResponsiveTable
              column={ROLE_PERMISSION_COLOUMN}
              rows={[]}
              height="calc(100vh - 300px)"
              emptyHeight={"calc(100vh - 255px)"}
              handleAddNewClick={handleAddnewModal}
            />
          </div>
        </div>
      </div>
      {/* Modal for adding new Member for the selected user */}
      {showAddMemberModal && (
        <AddMember
          handleClose={() => handleCloseModal()}
          visible={showAddMemberModal}
          headingLabel={selectedUserTypeRole}
        />
      )}
    </div>
  );
};
