import { FC } from "react";
import EmptyTable from 'assets/empty-table.svg'

import { Image } from "@storybook/image";

interface ITableEmptyMessage {
  height: string;
}

export const TableEmptyMessage: FC<ITableEmptyMessage> = ({ height }) => {
  return (
    <div className="empty-table" style={{ height }}>
      <Image url={EmptyTable} className="empty-table__image" />
    </div>
  );
};
