import { IButton } from ".";
import { FC } from "react";

import "./button.scss";

export const Button: FC<IButton> = ({
  label,
  handleClick,
  loader,
  type,
  icon,
  height,
  disabled,
  buttonType,
  prefixIcon,
  minHeight,
  width,
  fontColor,
  backgroundcolor,
}) => {
  return (
    <button
      className={`button ${type} ${disabled ? "button--disabled" : ""}`}
      onClick={handleClick}
      style={{ minHeight, height, width, backgroundColor: backgroundcolor }}
      disabled={disabled}
      type={buttonType}
    >
      {prefixIcon && <i className={prefixIcon} />}
      <div className="button-label-txt" style={{ color : fontColor ? fontColor : "white"}}>{label}</div>
      {loader}
      {icon && <i className={icon} />}
    </button>
  );
};
