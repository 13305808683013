import { Button } from "@storybook";
import { useMemo } from "react";
import { DASHBOARD_SETUP_COMPANY } from "../constant";
import "./dashboard-body.scss";

export const DashboardBody = () => {
  const handleSetupRows = useMemo(() => {
    return DASHBOARD_SETUP_COMPANY.INVITE_LIST.map((item: any) => (
      <>
        <div className="dashboard-body__setup-type-row">
          <div>
            <i className="ri-checkbox-circle-fill dashboard-body__setup-type-checked"></i>
          </div>
          <div className="dashboard-body__setup-type-name-wrapper">
            <div>
              <div className="dashboard-body__setup-type-name">
                {item.label}
              </div>
              <div className="dashboard-body__setup-type-subname">
                {item.desp}
              </div>
            </div>
            <div>
              <Button
                label={item.btn_label}
                width="100px"
                backgroundcolor="#E6E6E6"
                fontColor="black"
                type={"text"}
              />
            </div>
          </div>
        </div>
      </>
    ));
  }, []);

  return (
    <div className="dashboard-body-wrapper">
      <div className="dashboard-body">
        <div className="dashboard-body__setup-heading">
          <div className="dashboard-body__setup-heading-icon">
            <i className={DASHBOARD_SETUP_COMPANY.ICON}></i>
          </div>
          {DASHBOARD_SETUP_COMPANY.HEADING}
        </div>
        {handleSetupRows}
      </div>
    </div>
  );
};
