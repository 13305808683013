import { FC } from "react";
import { Button } from "@storybook";

import "./header.scss";

interface IHeader {
  title: string;
  showBtn?: boolean;
  btnLabel?: string;
  btnClass?: string;
  icon?: string;
  handleClickBtn?: () => void;
  disabled?: boolean;
}

export const Header: FC<IHeader> = ({
  title,
  showBtn,
  btnLabel,
  icon,
  btnClass = "",
  handleClickBtn = () => {},
  disabled,
}) => {
  return (
    <div className={`header header`}>
      <div className="header__name">{title}</div>
      <div className={`header__right ${btnClass}`}>
        {showBtn && (
          <Button
            type="button__filled--primary"
            label={btnLabel ?? ""}
            handleClick={handleClickBtn}
            prefixIcon={icon}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
