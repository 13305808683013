import { atom } from "recoil";

export const WebTokenState = atom<string>({
  key: "web-token-state",
  default: "",
});

export const IsBasicInformationDone = atom<boolean>({
    key: "is-basic-information-done",
    default: false,
  });
  