import { Button } from "@storybook/button";

import "./table-header.scss";

export const TableHeader = ({ showSearchBy, showFilterBtn, handleAddNewClick }: any) => {
  return (
    <div>
      <div className="table-header__user-role-header">
        <div></div>
        <div className="table-header__user-role-header-right-box">
          {showSearchBy && (
            <input
              className="table-header__user-role-header-right-input-box"
              type="search"
              placeholder="search"
            />
          )}
          {showFilterBtn && (
            <div className="table-header__user-role-header-right-icon-box">
              <i className="ri-filter-3-line"></i>
            </div>
          )}
          <Button handleClick={()=> handleAddNewClick()} label="Add member" backgroundcolor="black" />
        </div>
      </div>
    </div>
  );
};
