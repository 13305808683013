// packages import
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// local components import
import { ToggleRoute } from "./toggle-route";
import { SIDEBAR_TABS } from "routes";

// style sheets
import "./sidebar.scss";

interface ISidebar {
  brandLoaded: boolean;
}

export const Sidebar: FC<ISidebar> = () => {
  const [openToggle, setOpenToggle] = useState("");

  // hooks
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // local variables
  const [windowSizeWidth, setWindowSizeWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSizeWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  // this will change the tabs
  const handleChangeTab = useCallback(
    (tabName: string) => {
      navigate(`/${tabName}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  // here tabs are rendered using map
  const mapRender = useMemo(() => {
    const isLiveRoute = (ROUTE: any, SUB_ROUTE: any) => {
      let isExist = false;
      if (pathname === `/${ROUTE}` || pathname.includes(`/${ROUTE}`)) {
        isExist = true;
      } else {
        // eslint-disable-next-line array-callback-return
        SUB_ROUTE?.map((route: any) => {
          if (
            pathname === `/${route.ROUTE}` ||
            pathname.includes(`/${route.ROUTE}`)
          ) {
            isExist = true;
          }
        });
      }
      return isExist;
    };

    const lists = SIDEBAR_TABS;
    return (
      <div className="anything">
        {lists.tabs.map(
          ({ ICON, ROUTE = "", TAB_INDEX, TITLE, SUB_ROUTE }, index) => (
            <div key={TAB_INDEX}>
              <div
                className={`sidebar__item ${
                  isLiveRoute(ROUTE, SUB_ROUTE) ? "sidebar__item--active" : ""
                } `}
                onClick={() => {
                  if (!(SUB_ROUTE && windowSizeWidth > 1080)) {
                    handleChangeTab(ROUTE);
                  }
                }}
                key={`${TAB_INDEX}-${TITLE}`}
              >
                {windowSizeWidth < 1080 && (
                  <i className={`sidebar__icon ${ICON}`} id={ICON} />
                )}
                {SUB_ROUTE && windowSizeWidth > 1080 ? (
                  <ToggleRoute
                    index={index}
                    openToggle={openToggle}
                    setOpenToggle={setOpenToggle}
                    route={{ ICON, ROUTE, TAB_INDEX, TITLE, SUB_ROUTE }}
                    handleChangeTab={handleChangeTab}
                    pathname={pathname}
                  />
                ) : (
                  <div className={`sidebar__text  ${"d-none"} `}>{TITLE}</div>
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  }, [pathname, windowSizeWidth, openToggle, handleChangeTab]);

  // this will manage the click event on the logo
  const handleLogoClick = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <div
      className={`sidebar-container ${"open-sidebar"}`}
      style={{ width: "300px" }}
    >
      <div
        onClick={() => handleLogoClick("/onboarding")}
        className="sidebar-container__user-box"
      >
          <div className="sidebar-container__back-btn-box">
            <i className="ri-arrow-left-line sidebar-container__back-btn-icon"></i>
        {windowSizeWidth > 1080 && (
            "Back to Simplici"
            )}
          </div>
        
        <div className="sidebar-container__user-profile-box">
          <div className="sidebar-container__user-profile"></div>
        {windowSizeWidth > 1080 && (

          <div className="sidebar-container__user-name">Satschel</div>
)}
        </div>
      </div>
      <div className="side-Container">
        <div className="outside-sidebar">
          <div className="sidebar">{mapRender}</div>
        </div>
      </div>
    </div>
  );
};
