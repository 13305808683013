import type { IReactResponsiveTable } from "./type";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "@storybook";

import { getDate, getDateWithTime, getTime, useFormatNumber } from "utils";

import "./react-responsive-table.scss";
import { TableEmptyMessage } from "./components";
import { TableHeader } from "./table-header";

export const ReactResponsiveTable: FC<IReactResponsiveTable> = ({
  tableType,
  column,
  rows = [],
  isLoading = false,
  className = "",
  columnHandle = false,
  handleAddNewClick,
}) => {
  const [searchedText] = useState<string>("");
  const [tableRowsData, setTableRows] = useState<any[]>(rows);
  const selectedDate: any = {};

  useEffect(() => {
    setTableRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const formatDate = useCallback((date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }, []);

  const checkSearch = useCallback(
    (name: string | number | undefined) => {
      return name
        ?.toString()
        ?.toLowerCase()
        ?.includes?.(searchedText.toLowerCase());
    },
    [searchedText]
  );

  const tableData = useMemo(
    () =>
      tableRowsData.filter((item) => {
        if (
          // we will check here the item
          checkSearch(item?.label)
        ) {
          return true;
        }

        let date = new Date(formatDate(item.createdAt)).getTime();
        if (date >= selectedDate.fromDates && date <= selectedDate.endDates) {
          return item;
        }
        return <></>;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkSearch,
      tableRowsData,
      searchedText,
      searchedText.length,
      selectedDate.endDates,
      selectedDate.fromDates,
    ]
  );

  const { numberDecimal } = useFormatNumber();

  const renderColumn = useMemo(
    () =>
      column.map(({ label, key, width }, index) => (
        <th
          className="table__head-data"
          key={`renderColumns__${label}_${index}-${key}`}
          style={{ width }}
        >
          <div className="table__head-data--label">
            <span>{label} </span>
          </div>
        </th>
      )),
    [column]
  );

  const renderRows = useMemo(() => {
    const renderingRows = tableData;
    return renderingRows.map((item, index) => (
      <tr
        key={`renderRows_${item.createdAt}__${index}`}
        className={`${columnHandle ? "row__hover" : ""}`}
      >
        {column.map(({ key, format, width, className }, idx) => {
          let value = item[key];
          if (value) {
            switch (format) {
              case "number":
                break;
              case "date":
                value = `${getDate(value)}  ${getTime(value)}`;
                break;
              case "dateTime":
                value = getDateWithTime(value);
                break;
              case "jsx":
                value = value();
                break;
              case "price":
                value = `$${numberDecimal(Number(value))}`;
                break;
              case "percentage":
                value = `${value}%`;
                break;
              default:
                break;
            }
          }
          return (
            <td
              key={`renderColumn_${index}__${idx}__${key}`}
              className={`${className ? className : ""}`}
              style={{ width }}
            >
              <div
                className={`${
                  format === "string"
                    ? tableType === "pipeLine"
                      ? "text-elipsis-pipeline"
                      : "text-elipsis"
                    : ""
                }`}
              >
                {value == null || value === "" ? "--" : value}
              </div>
            </td>
          );
        })}
      </tr>
    ));
  }, [column, columnHandle, numberDecimal, tableData, tableType]);

  const isEmpty = useMemo(() => {
    if (!tableData.length && !renderRows.length) return true;
    return false;
  }, [renderRows.length, tableData.length]);

  return (
    <div className="react-table-responsive-container">
      <TableHeader
        handleAddNewClick={handleAddNewClick}
        showSearchBy={true}
        showFilterBtn={true}
      />

      <div className="reactTableResponsiveWrapper">
        <table className={`react-table-responsive fl-table ${className}`}>
          <thead className="react-table-responsive__head">
            <tr
              className="react-table-responsive__head-row"
              style={{ backgroundColor: "#f1f2f2" }}
            >
              {renderColumn}
            </tr>
          </thead>
          {!!tableData.length && !!renderRows.length && (
            <tbody className="react-table-responsive__body ">
              {renderRows}
            </tbody>
          )}
        </table>

        {!tableData.length && isLoading && (
          <div className="loader-container">
            <Loader className="loader-blue" dimension={52} />
          </div>
        )}

        {isEmpty && !isLoading && <TableEmptyMessage height={"250px"} />}
      </div>
    </div>
  );
};
