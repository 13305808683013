import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { REACT_APP_API_HOST as API_HOST } from "envs";
import { WebTokenState } from "states";

interface IConfig {
  headers?: {
    Authorization?: string;
    "Content-Type"?: string;
  };
}
interface IProps {
  updateState?: boolean;
}

export const useNetwork = (
  { updateState = true }: IProps = { updateState: true }
) => {
  const webToken = useRecoilValue(WebTokenState);
  const accessToken =
    webToken?.length > 0 ? webToken : "" ;
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);

  const config: IConfig = useMemo(() => ({}), []);
  const postConfig: IConfig = useMemo(() => ({}), []);

  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  postConfig.headers = {
    "Content-Type": "application/json",
    ...(config.headers ?? {}),
  };

  const get = useCallback(
    async (url: string, configHeader?: any): Promise<any> => {
      setLoading(true);
      try {
        const response = await fetch(API_HOST + url, configHeader ?? config);
        const apiPayload = await response.json();
        if (response.status === 401 || response.status === 403) {
          //   moveToLoginPage();
          return;
        }
        if (updateState) {
          setStatus(response?.ok);
          setIsLoaded(true);
          setData(apiPayload);
        }
        return apiPayload;
      } catch (err: any) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    [config, updateState]
  );

  const post = useCallback(
    async (url: string, requestJSON: any) => {
      if (updateState) {
        setLoading(true);
      }
      try {
        const response = await fetch(API_HOST + url, {
          method: "POST",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        setResponse(response);
        if (response.status === 500) {
          if (updateState) {
            setError(response.type);
          }
        }
        if (updateState) {
          setStatus(response?.ok);
        }
        const apiData = await response.json();
        if (url.includes("charts?businessId")) {
          if (updateState) {
            setIsLoaded(true);
            setData(apiData);
          }
          return apiData;
        }
        const apiResponse = apiData.data ?? apiData;
        if (updateState) {
          setIsLoaded(true);
          setData(apiResponse);
        }
        return apiResponse;
      } catch (err: any) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    [updateState, postConfig]
  );

  const formData = useCallback(
    async (url: string, requestJSON: any) => {
      setLoading(true);
      try {
        const response = await fetch(API_HOST + url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: requestJSON,
        });
        const apiData = await response.json();
        if (updateState) {
          setStatus(response?.ok);
          setIsLoaded(true);
          setData(apiData);
          setLoading(false);
        }
        return apiData;
      } catch (err) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    [accessToken, updateState]
  );

  const put = useCallback(
    async (url: string, requestJSON?: any) => {
      try {
        const response = await fetch(API_HOST + url, {
          method: "PUT",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        if (updateState) {
          setStatus(response?.ok);
        }
        const apiData = await response.json();
        if (updateState) {
          setStatus(response.status);
          setIsLoaded(true);
          setData(apiData.data);
        }
        return apiData.data;
      } catch (err: any) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    [postConfig, updateState]
  );

  const remove = useCallback(
    async (url: string, requestJSON?: any) => {
      setLoading(true);
      try {
        const response = await fetch(API_HOST + url, {
          method: "DELETE",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        if (updateState) {
          setStatus(response?.ok);
        }
        const apiData = await response.json();
        if (updateState) {
          setStatus(response.status);
          setIsLoaded(true);
          setData(apiData.data);
        }
        return apiData.data;
      } catch (err: any) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    [postConfig, updateState]
  );

  const patch = useCallback(
    async (url: string, requestJSON?: any) => {
      if (updateState) {
        setLoading(true);
      }
      try {
        const response = await fetch(API_HOST + url, {
          method: "PATCH",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        if (updateState) {
          setStatus(response?.ok);
        }
        const apiData = await response.json();
        if (updateState) {
          setIsLoaded(true);
        }
        const apiResponse = apiData.data ?? apiData;
        if (updateState) {
          setData(apiResponse);
        }
        return apiData.data;
      } catch (err: any) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postConfig, accessToken]
  );

  return {
    get,
    post,
    formData,
    put,
    data,
    status,
    error,
    loading,
    setLoading,
    remove,
    patch,
    isLoaded,
    setIsLoaded,
    response,
  };
};
