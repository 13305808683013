export const DASHBOARD_SETUP_COMPANY = {
  ICON: "ri-building-line",
  HEADING: "Setup Company",
  INVITE_LIST: [
    {
      label: "Invite company adminstrators",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Invite",
    },
    {
      label: "Invite board members",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Invite",
    },
    {
      label: "Set roles and signatories",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Assign Role",
    },
    {
      label: "Invite legal contact",
      desp: "Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec.",
      btn_label: "Invite",
    },
  ],
};
