import { useCallback, useMemo, useState } from "react";
import { Button, Input, Loader, ReactDropdown } from "@storybook";
import { useSetRecoilState } from "recoil";
import { useNetwork, useNotification } from "hooks";
import { CustomUpload } from "./component/custom-upload";
import { AddtionalInfoHeader } from "./component/header";
import { AddtionalInfoState } from "./store/states";
import { API_URL } from "constant/api";
import { companyTypes } from "./constant";
import "./additional-info.scss";
import { UplaodedFiles } from "./component/uplaoded-files/uploaded-files";

export const BasicInformationScreen = () => {
  const { formData, loading } = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const SetAddtionInfoState = useSetRecoilState(AddtionalInfoState);
  const [basicInfoData, setBasicInfoData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "Private  Company",
    einNumber: "",
    companyFormationDocs: [],
  });

  const handleFormData = useCallback(
    (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        newState[type] = value;
        return newState;
      });
    },
    []
  );

  const handleDropChange = useCallback(
    (item: { label: string; value: string }) => {
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        newState["companyType"] = item?.value;
        return newState;
      });
    },
    []
  );

  const handleDropData = useCallback(
    (item: any) => {
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        var data = [...basicInfoData.companyFormationDocs, ...item];
        newState["companyFormationDocs"] = data;
        return newState;
      });
    },
    [basicInfoData.companyFormationDocs]
  );


  const controlStyle = {
    minHeight: 42,
    minWidth: 180,
    height: 42,
    borderRadius: 0,
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    "&:hover": { borderColor: "black", cursor: "pointer" },
    fontWeight: 500,
    fontSize: 14,
  };

  const optionStyle = {
    height: 42,
    minHeight: 42,
    "&:hover": { background: "var(--color-black)", color: "white" },
  };

  const handleSumbit = useCallback(async () => {
    const payload = new FormData();
    payload.append("firstName", basicInfoData.firstName);
    payload.append("lastName", basicInfoData.lastName);
    payload.append("companyName", basicInfoData.companyName);
    payload.append("companyType", basicInfoData.companyType);
    payload.append("einNumber", basicInfoData.einNumber);
    basicInfoData.companyFormationDocs.map((item) => {
      return payload.append("docs", item);
    });
    const resp = await formData(API_URL.ADD_BASIC_INFORMATION, payload);
    if (resp.data) {
      SetAddtionInfoState("bank-information");
    } else {
      errorNotification(resp.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    SetAddtionInfoState,
    basicInfoData.companyFormationDocs,
    errorNotification,
    formData,
  ]);
  const handleDeleteFile = useCallback(
    (selectedItem: any) => {
      const data = basicInfoData.companyFormationDocs;
      const filteredItems = data.filter(function (item: any) {
        return item.name !== selectedItem.name;
      });
      setBasicInfoData((prevState) => {
        const newState: any = { ...prevState };
        newState["companyFormationDocs"] = filteredItems;
        return newState;
      });
      successNotification("file deleted successfully");
    },
    [basicInfoData.companyFormationDocs, successNotification]
  );

  const nextBtnLabel = useMemo(() => {
    if (loading) {
      return <Loader className="loader-white" type="loader" dimension={14} />;
    }
    return "Next";
  }, [loading]);

  return (
    <div className="basic-infor-wrapper">
      <AddtionalInfoHeader
        width="50%"
        heading="Basic information"
        subHeading="Suspendisse vitae tortor sed ipsum. Nibh purus ipsum lacus urna donec."
      />
      <div className="info-wrapper">
        <div className="info-wrapper__flex-input">
          <Input
            label={"First Name *"}
            inputType={"text"}
            placeholder={"john"}
            handleChange={(e) => handleFormData("firstName", e)}
          />
          <Input
            label={"Last Name *"}
            inputType={"text"}
            placeholder={"doe"}
            handleChange={(e) => handleFormData("lastName", e)}
          />
        </div>
        <Input
          label={"Company Name *"}
          inputType={"text"}
          placeholder={"google"}
          handleChange={(e) => handleFormData("companyName", e)}
        />
        <div className="info-wrapper__flex-input">
          <div className="info-wrapper__dropdown-box">
            <Input
              label={"EIN Number *"}
              inputType={"text"}
              placeholder={"PAJS281A"}
              handleChange={(e) => handleFormData("einNumber", e)}
            />
          </div>

          <div className="info-wrapper__dropdown-box">
            <ReactDropdown
              label={"Company Type *"}
              controlStyle={controlStyle}
              optionsStyle={optionStyle}
              handleChangeSelect={handleDropChange}
              options={companyTypes}
              value={{
                label: basicInfoData?.companyType,
                value: basicInfoData?.companyType,
              }}
              defaultValue={companyTypes[0]}
            />
          </div>
        </div>
        <CustomUpload handleDropData={handleDropData} />
        {basicInfoData?.companyFormationDocs.length > 0 && (
          <UplaodedFiles
            files={basicInfoData.companyFormationDocs}
            handleDeleteFile={handleDeleteFile}
          />
        )}
      </div>
      <div className="basic-infor-wrapper__footer">
        <Button
          width="100px"
          backgroundcolor="black"
          label={nextBtnLabel}
          handleClick={handleSumbit}
          type={"sumbit"}
        />
      </div>
    </div>
  );
};
