import { FC, useCallback, useState } from "react";
import { Button, Input, ReactDropdown } from "@storybook";
import { SUB_ROLE_TYPE } from "../constant";
import "./add-member.scss";

interface IModal {
  visible: boolean;
  handleClose: any;
  headingLabel: string;
}

export const AddMember: FC<IModal> = ({
  visible,
  handleClose,
  headingLabel,
}) => {
  const [addMemberData, setAddMemberData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    startDate: "",
    endDate: "",
  });
  const controlStyle = {
    minHeight: 42,
    minWidth: 180,
    height: 42,
    borderRadius: 0,
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    "&:hover": { borderColor: "black", cursor: "pointer" },
    fontWeight: 500,
    fontSize: 14,
  };

  const optionStyle = {
    height: 42,
    minHeight: 42,
    "&:hover": { background: "var(--color-black)", color: "white" },
  };

  if (!visible) {
    return null;
  }

  console.log("add new member data ", addMemberData);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleChangeData = useCallback(
    (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setAddMemberData((prevState: any) => {
        const newState: any = { ...prevState };
        newState[type] = value;
        return newState;
      });
    },
    []
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleDropChange = useCallback(
    (item: { label: string; value: string }) => {
      setAddMemberData((prevState: any) => {
        const newState: any = { ...prevState };
        newState["role"] = item?.value;
        return newState;
      });
    },
    []
  );

  return (
    <div className="modal-main">
      <div className="modal-main__content">
        <div className="modal-main__heading-row">
          <div className="modal-main__label">{headingLabel}</div>
          <div className="modal-main__delete-icon">
            <i className="ri-close-circle-line"></i>
          </div>
        </div>
        <div className="form-body">
          <div className="form-body__row">
            <div className="form-body__half-space">
              <Input
                handleChange={(event) => handleChangeData("firstName", event)}
                inputType={"text"}
                placeholder={"first Name"}
                label="First Name"
              />
            </div>
            <div className="form-body__half-space">
              <Input
                inputType={"text"}
                placeholder={"Last Name"}
                label="Last Name"
                handleChange={(event) => handleChangeData("lastName", event)}
              />
            </div>
          </div>
          <Input
            inputType={"text"}
            placeholder={"email"}
            label="Email"
            handleChange={(event) => handleChangeData("email", event)}
          />
          <div className="form-body__row">
            <div className="form-body__half-space">
              Role *
              <div className="form-body__role-desp">
                Board member will be added to account users and will ave access
                to view the company’s cap table.
              </div>
            </div>
            <div className="form-body__half-space">
              <ReactDropdown
                controlStyle={controlStyle}
                optionsStyle={optionStyle}
                handleChangeSelect={handleDropChange}
                options={SUB_ROLE_TYPE}
                value={{
                  label: "Select",
                  value: "value",
                }}
                defaultValue={SUB_ROLE_TYPE[0]}
              />
            </div>
          </div>
          <div className="form-body__row">
            <div className="form-body__half-space">
              <Input
                inputType={"date"}
                placeholder={"Start Date"}
                label="Start Date"
                handleChange={(event) => handleChangeData("startDate", event)}
              />
            </div>
            <div className="form-body__half-space">
              <Input
                inputType={"date"}
                placeholder={"End Name"}
                label="End Date"
                handleChange={(event) => handleChangeData("endDate", event)}
              />
            </div>
          </div>
        </div>
        <div className="add-member-footer">
          <div className="add-member-footer__right">
            <Button
              label={"Cancel"}
              width="100px"
              handleClick={() => handleClose()}
              type={"sumbit"}
              fontColor="black"
              backgroundcolor="#f0f4ff"
            />
            <Button
              label={"Add member"}
              width="100px"
              backgroundcolor="black"
              handleClick={(e) => e}
              type={"sumbit"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
