import { useCallback } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ToastContainer } from "react-toastify";
import { AdditionalInformation, DashBoard } from "views";
import { useCookie } from "hooks";
import { ROUTES } from "./routes/index";
import { IsBasicInformationDone } from "states";
import "react-toastify/dist/ReactToastify.css";
import "remixicon/fonts/remixicon.css";

const { DASHBOARD, NO_ROUTE, BASIC_INFORMATION } = ROUTES;

const App = () => {
  const { token } = useParams();
  console.log("token", token);
  const { get: getCookie } = useCookie();
  const localUserData = getCookie("user");
  const basicInformationDone = useRecoilValue(IsBasicInformationDone);

  const handleDashboardRoute = useCallback(() => {
    if (localUserData?.IsBasicInfoDone || basicInformationDone) {
      return <DashBoard />;
    } 
    return <AdditionalInformation />;
    
  }, [basicInformationDone, localUserData?.IsBasicInfoDone]);

  return (
    <div className="App">
      <ToastContainer></ToastContainer>
      <BrowserRouter>
        <Routes>
          <>
            <Route path={NO_ROUTE} element={handleDashboardRoute()} />
            <Route path={NO_ROUTE} element={<Navigate to={DASHBOARD} />} />
            <Route
              path={BASIC_INFORMATION}
              element={<AdditionalInformation />}
            />
          </>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
