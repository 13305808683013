import { useCallback, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import { DashboardPage } from "views/dashboard-page";
import { RoleAndPermission } from "views/role-and-permission";
import { Sidebar } from "views/sidebar";
import "./dashboard.scss";

// routes
const {
  DASHBOARD,
  DATA_ROOMS,
  SETTINGS,
  SHARES,
  SHARE_CLASS,
  FINANCIALS,
  TAXES,
  TEMPLATES,
  EQUITY_PLAN,
  STAKEHOLDER,
  OPTIONS,
  CONVERTiBLES,
} = ROUTES;

export const DashBoard = () => {
 
  const navigate = useNavigate();

  const getCookie = (key: string) => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodeCookie = decodedCookie.split(";");
    for (let i = 0; i < decodeCookie.length; i++) {
      let decodeKey: any = decodeCookie[i];
      while (decodeKey.charAt(0) === " ") {
        decodeKey = decodeKey.substring(1);
      }
      if (decodeKey.indexOf(name) === 0) {
        return decodeKey.substring(name.length, decodeKey.length);
      }
    }
    return "";
  };

  const localStorageData = getCookie("user" ?? "");

  useEffect(() => {
    if (!localStorageData) {
      navigate(ROUTES.LOG_IN);
    }
  }, [localStorageData, navigate]);

  const routing = useCallback(
    () => {
      return (
        <Routes>
          <Route path={`${DASHBOARD}`} element={<DashboardPage />} />
          <Route path={`${DATA_ROOMS}`} element={<DashboardPage />} />
          <Route path={`${SHARES}`} element={<DashboardPage />} />
          <Route path={`${SHARE_CLASS}`} element={<DashboardPage />} />
          <Route path={`${SETTINGS}`} element={<DashboardPage />} />
          <Route path={`${FINANCIALS}`} element={<DashboardPage />} />
          <Route path={`${TAXES}`} element={<RoleAndPermission />} />
          <Route path={`${TEMPLATES}`} element={<DashboardPage />} />
          <Route path={`${EQUITY_PLAN}`} element={<DashboardPage />} />
          <Route path={`${STAKEHOLDER}`} element={<DashboardPage />} />
          <Route path={`${OPTIONS}`} element={<DashboardPage />} />
          <Route path={`${CONVERTiBLES}`} element={<DashboardPage />} />
          <Route path="/*" element={<Navigate to={DASHBOARD} />} />
        </Routes>
      );
    },
    []
  );

  return (
    <div>
      <div className="main-body">
        <Sidebar brandLoaded={false} />
        {routing()}
      </div>
    </div>
  );
};
