export const BANKS = [
  {
    bankName: "Bank account (Instant) ACH",
    description: "Some Description",
  },
  {
    bankName: "Bank account (Manual) ACH",
    description: "Some Description",
  },
  {
    bankName: "Wire transfer account",
    description: "Some Description",
  },
];

 export const companyTypes = [
  {
    label: "Private  Company",
    value: "Private  Company",
  },
  {
    label: "Public Company.",
    value: "Public Company.",
  },
  {
    label: "Section 8 Company (NGO)",
    value: "Section 8 Company (NGO)",
  },
  {
    label: "Micro Companies.",
    value: "Micro Companies.",
  },
  {
    label: "By Shares.",
    value: "By Shares.",
  },
];